.matchdata {
  display: table;
  table-layout: fixed;
  width: 100%;
  box-sizing: border-box;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; */
  /* background-color: rgb(241,241,241); */
}

.driverblock {
  /* background-color: blue; */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* font-weight: 700; */
  color: #373f49;
  font-size: 1.2em;
}

.competitorname {
  color: #373f49;
  font-size: 0.8em;

  /* font-size: small; */
}

.positionblock {
  /* background-color: green; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: small;
}

.score {
  font-size: 1.2em;
  font-weight: bolder;
}

.logocontainer {
  width: 40px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo {
  width: auto;
  max-width: 40px;
  height: auto;
  max-height: 50px;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
