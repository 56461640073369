.post {
  max-width: 600px;
  margin: auto;
  font-family: "Roboto", cursive;
}

.previewTitle {
  font-size: 1.5em;
}

.preview {
  margin: 0.2vh 0 1vh;
  /* background: radial-gradient(circle, rgb(235, 235, 235) 0%, rgba(255,255,255,1) 85%); */
  background-color: rgb(255, 255, 255);
}

.topcontent {
  /* background-color: rgb(241, 241, 241); */
  background-color: rgb(250, 250, 250);
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.titlelogocontainer {
  width: 3.5vh;
  height: 3.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.titlelogo {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.competitioncontent {
  height: 100%;
}

.competition {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  min-height: 4.5vh;
}

.competitionname {
  color: #373f49;
  font-weight: 400;
  font-size: medium;
  line-height: 0.9;
}

.competitiondetail {
}

.eventcontainer {
  display: flex;
  flex: row;
}

.eventspacer {
  width: 3.5vh;
  margin-right: 10px;
}

.event {
  font-size: small;
  color: #677383;
  line-height: 0.9;
}

.tag {
  color: #a0a0a0;
  text-decoration: none;
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  line-height: 1;
  border-radius: 2px;
  border: 1px solid #a0a0a0;
  margin-right: 0.5em;
}

.matchdata {
  margin-top: 2vh;
  padding-bottom: 2vh;
}

.card {
  padding: 0px 10px 10px 10px;
  color: #373f49;
}

.topshadow {
  height: 0.8vh;
  background: linear-gradient(
    0deg,
    rgba(200, 200, 200, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
}

.bottomshadow {
  height: 0.8vh;
  background: linear-gradient(
    180deg,
    rgba(200, 200, 200, 1) 0%,
    rgba(240, 240, 240, 1) 100%
  );
}

.block {
  height: 35vh;
  padding: 5px;
}

.commentblock {
  padding: 5px;
}

.divider {
  background-color: white;
  background-color: #93a1b4;
  height: 1px;
  width: 100%;
}

.paras {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 0.9em;
}

.para {
  margin: 0;
  font-family: "Roboto";
}

.video {
  width: 95%;
  height: 95%;
}
.videocontainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(241, 241, 241);
  font-family: "Roboto";
  text-align: center;
}

.selectors {
  display: table;
  table-layout: fixed;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  font-family: "roboto";
  font-size: medium;
}

.selectorbox {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
}

.selector,
.selected {
  height: 30px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  letter-spacing: 0.8px;
  padding: 0px 8px;
}

.selected {
  border: none;
  outline: none;
  padding-bottom: 0px;
  font-weight: bold;
}

.selectorline {
  height: 1px;
  width: 100%;
  /* background-color: green; */
  background-color: rgba(0, 0, 0, 0);
}

.selectedline {
  height: 1px;
  width: 100%;
  background-color: black;
  animation: selectorLine 0.5s;
}

@keyframes selectorLine {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.selector {
  padding-bottom: 1px;
}

.likeButton,
.likedButton {
  width: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.like,
.liked {
  height: 20px;
  width: 20px;
}

.likeButton {
  background-color: lightgray;
}

.likedButton {
  background-color: lightgray;
  color: rgb(30, 13, 126);
}

.engagementblock {
  display: flex;
  flex-direction: column;
}

.engagementdatablock {
  display: flex;
  flex-direction: column;
  margin: 5px 10px;
}

.engagementbuttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.engagementDataIcon {
  height: 14px;
  width: auto;
  filter: invert(100%);
}

.engagementDataIconComment {
  height: 14px;
  width: auto;
  /* filter:invert(100%); */
  filter: brightness(0) invert(1);
  /* filter: saturate(0); */
  /* filter: hue-rotate(180); */
}

.engagementDataIconBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    45deg,
    rgba(30, 13, 126, 1) 0%,
    rgb(83, 51, 255) 93%
  );
  border-radius: 30%;
  padding: 3px;
  margin: 0 5px 0 0;
}

.engagementData {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: #424c58;
}

.engagementItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  height: 40px;
}

.engagementAction {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(194, 194, 194, 0);

  color: #424c58;
  padding: 0;
}
button {
  background-color: unset;
}

.engagementActionIcon,
.engagementActionIconSelected {
  width: auto;
  height: 22px;
  margin-right: 7px;
}
.engagementActionIconSelected {
  /* filter: invert(18%) sepia(78%) saturate(5888%) hue-rotate(251deg) brightness(101%) contrast(101%); */
  filter: invert(24%) sepia(68%) saturate(2747%) hue-rotate(239deg)
    brightness(62%) contrast(126%);
}
.engagementtop {
  height: 1px;
  width: 100%;
  background-color: rgb(209, 208, 208);
}

.cheered {
  color: rgba(40, 23, 166);
}

.engagementtable {
  display: table;
  table-layout: fixed;
  width: 100%;
  box-sizing: border-box;
}

.engagementbox {
  display: table-cell;
  vertical-align: middle;
}

.engagementboxcontent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.extraCheer {
  /* background-color: blue; */
  line-height: 300%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.extraCheerAction {
  background-color: rgb(241, 241, 241);
  width: 100%;
  text-align: center;
  padding: 5px;
  font-size: medium;
  margin-bottom: 10px;
  font-family: "Roboto";
}

.extraCheerLine {
  width: 100%;
  height: 1px;
  background-color: rgb(100, 100, 100);
}
