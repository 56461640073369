/* @font-face {
  font-family: "Avenir";
  font-weight: 400;
  font-style: normal;
  src: url("/avenir-400.woff2") format("woff2");
  font-display: swap;
} */
/* 
body {
  font-family: "Avenir", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.65;
  color: #373F49;
  background: #eee;
  margin: 0;
} */
@import url("https://fonts.googleapis.com/css?family=Roboto");

body {
  /* font-family: "Roboto"; */
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3 {
  font-size: 2em;
  font-weight: normal;
}

a {
  color: currentColor;
}

button {
  color: currentColor;
  border: none;
  /* background-color: white; */
  height: 6vh;
}
/* v */

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

:root a:hover {
  color: currentColor;
  text-decoration: none;
}

/* .wrapper { */
/* background-color: lightgrey; */
/* } */

/**
 * article grid
 */
.article-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2vmin;
  background-color: rgb(240, 240, 240);
}

/**
 *
 */
.section-headline {
  padding: 0 0 0.4em 0;
  margin: 0 0 5vmin 0;
  border-bottom: 1px solid #ddd;
}

/**
 *
 */
.list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-inline li {
  display: inline-block;
}

.underheader {
  height: 10vh;
  width: 100%;
}

/* POST HEADLINE COLOURS */
.Rugby,
.Football,
.Motorsport,
.Cricket,
.Golf,
.Tennis,
.Olympics {
  padding: 0vw 5vw;
  color: white;
  border-bottom-left-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Roboto";
}

.Rugby {
  background: linear-gradient(
    175deg,
    rgba(0, 128, 0, 1) 0%,
    rgba(0, 161, 0, 1) 100%
  );
}
.Rugbyheader {
  background-color: rgba(0, 128, 0, 1);
}
.Rugbyborder {
  background: linear-gradient(
    175deg,
    rgba(0, 128, 0, 1) 0%,
    rgba(0, 161, 0, 1) 100%
  );
}

.Football {
  background: linear-gradient(
    175deg,
    rgba(18, 88, 242, 1) 0%,
    rgba(64, 118, 235, 1) 100%
  );
}
.Footballheader {
  background-color: rgb(18, 88, 242);
}
.Footballborder {
  background: linear-gradient(
    175deg,
    rgba(18, 88, 242, 1) 0%,
    rgba(64, 118, 235, 1) 100%
  );
}

.Cricket {
  background: linear-gradient(
    175deg,
    rgb(190, 18, 242) 0%,
    rgb(202, 95, 235) 100%
  );
}
.Cricketheader {
  background-color: rgb(190, 18, 242);
}
.Cricketborder {
  background: linear-gradient(
    175deg,
    rgb(190, 18, 242) 0%,
    rgb(202, 95, 235) 100%
  );
}

.Motorsport {
  background: linear-gradient(
    175deg,
    rgb(241, 188, 41) 0%,
    rgb(250, 200, 0) 100%
  );
}
.Motorsportheader {
  background-color: rgb(241, 188, 41);
}
.Motorsportborder {
  background: linear-gradient(
    175deg,
    rgb(241, 188, 41) 0%,
    rgb(250, 200, 0) 100%
  );
  border-radius: 5px;
}

.Golf {
  background: linear-gradient(
    175deg,
    rgb(0, 128, 128) 0%,
    rgb(0, 158, 158) 100%
  );
}
.Golfheader {
  background-color: rgb(0, 128, 128);
}
.Golfborder {
  background: linear-gradient(
    175deg,
    rgb(0, 128, 128) 0%,
    rgb(0, 168, 168) 100%
  );
  border-radius: 5px;
}

.Tennis {
  background: linear-gradient(175deg, rgb(197, 2, 2) 0%, rgb(230, 66, 66) 100%);
}
.Tennisheader {
  background-color: rgb(197, 2, 2);
}
.Tennisborder {
  background: linear-gradient(175deg, rgb(197, 2, 2) 0%, rgb(230, 66, 66) 100%);
  border-radius: 5px;
}

.Olympics {
  background: linear-gradient(
    175deg,
    rgba(139, 32, 48) 0%,
    rgba(159, 52, 68, 1) 100%
  );
}
.Olympicsheader {
  background-color: rgba(139, 32, 48, 1);
}
.Olympicsborder {
  background: linear-gradient(
    175deg,
    rgba(139, 32, 48) 0%,
    rgba(159, 52, 68, 1) 100%
  );
}

.Rugbyheader,
.Footballheader,
.Motorsportheader,
.Cricketheader,
.Golfheader,
.Tennisheader,
.Olympicsheader {
  width: 100%;
  height: 2px;
}

.Rugbyborder,
.Footballborder,
.Motorsportborder,
.Cricketborder,
.Golfborder,
.Tennisborder,
.Olympicsborder {
  border-radius: 5px;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}

.ldsring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(96, 97, 116) transparent transparent transparent;
}

.ldsring div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsring div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ldsringwhite {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.ldsringwhite div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.ldsringwhite div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsringwhite div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsringwhite div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
