.container {
  /* background-color: aquamarine; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.large {
  width: 90%;
  height: auto;
}

.normal {
  width: 55%;
  height: auto;
}
