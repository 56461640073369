.container {
  max-width: 500px;
  margin: auto;
  color: white;
  font-size: 22px;
}

.buttons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  padding: 10px 30px;
  margin: 20px;
  border-radius: 5px;
  background-color: darkblue;
  border: 1px solid white;
}

.appStore {
  width: 150px;
  margin: 20px;
}
