.matchdata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.team {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  color: #373f49;
}

.score {
  font-weight: bolder;
  text-align: center;
  font-size: 1.2em;
}

.logocontainer {
  width: 40px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.versuscontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.versus {
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
}

.logo {
  width: auto;
  max-width: 40px;
  height: auto;
  max-height: 50px;
}

.teamname {
  color: #373f49;
  display: flex;
  flex-direction: column;
  align-items: center;
}
